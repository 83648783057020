import { useCallback, useState } from "react";
import axios from "axios";
import { OrionType } from "../types/api/orionType";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useOrionTypes = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { tokenRefresh } = useLoginUser();
  const [orionTypes, setOrionTypes] = useState<Array<OrionType>>([]);
  const [selectedType, setSelectedType] = useState<OrionType | undefined>(
    undefined
  );

  const ORION_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getOrionTypes = useCallback((tenant_id: string) => {
    setLoading(true);

    let atr: string = "";
    if (tenant_id != "999999") {
      atr = "?limit=1000&options=noAttrDetail&tenant=" + tenant_id;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<OrionType>>(ORION_MANAGER_URL + "/types" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 200) {
            setOrionTypes(res.data);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テンプレートの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  const getOrionType = useCallback((tenant_id: string, typeName: string) => {
    setLoading(true);
    setSelectedType(undefined);

    let atr: string = "";
    if (tenant_id != "999999") {
      atr = "?tenant=" + tenant_id;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<OrionType>(ORION_MANAGER_URL + `/types/${typeName}` + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 200) {
            setSelectedType(res.data);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テンプレートの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getOrionTypes,
    getOrionType,
    loading,
    orionTypes,
    selectedType,
  };
};
