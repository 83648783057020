import { StreamInputype } from "./commonTypes";


export interface EntityTypePtn {
  readonly TypeName: string;
  readonly Subscriptions: SubscriptionInfo[];
  readonly optionApi?: ApiInfo[];
  readonly provider?: ProviderInfo;
  readonly schema?: object;
  readonly fiwareApp?: FiwareApp;
}

declare enum JsonSchemaType {
  NULL = "null",
  BOOLEAN = "boolean",
  OBJECT = "object",
  ARRAY = "array",
  NUMBER = "number",
  INTEGER = "integer",
  STRING = "string"
}
// 各プロパティの定義
interface PropertyDefinition {
  type: JsonSchemaType;
  title: string;
  list: boolean;
  edit: boolean;
}

// properties内の属性名が不定の場合の型定義
interface FiwareApp {
  properties: Record<string, PropertyDefinition>;
}

export interface SubscriptionInfo {
  SubscriptionPtn: string;
  description?: string;
  subject: SubjectInfo;
  notification: NotificationInfo;
  throttling?: number;
}

export interface SubjectInfo {
  entities: any[];
  condition: any;
}

export interface NotificationInfo {
  attrs?: string[];
  http?: any;
  attrsFormat?: string;
  exceptAttrs?: string[];
  expression?: any;
  alterationTypes?: string[];
  metadata?: string[];
}

export const ApiPtn = {
  binary: "binary",
  readonly: "readonly",
  Data_in_chronological: "Data_in_chronological",
  no_type: "no_type",
} as const;
export type ApiPtnType = (typeof ApiPtn)[keyof typeof ApiPtn];

export interface ApiInfo {
  ApiPtn: ApiPtnType;
  ContentType?: string;
}

export interface ProviderInfo {
  url: string;
  attrs: string[];
}

export interface DevicePtn {
  DeviceName: string;
  NestStak: StreamInputype;
  ChangFunctionArn?: string;
  ApiKey?: string;
  Topic?: string;
  EntityType?: string;
  ChangJobName?: string;
  ChangJobRoleArn?: string;
  ChangNgsiJobName?: string;
  MonthlyJobName?: string;
  MonthlyJobRoleArn?: string;
  OpenSearchIndexTemplate?: object;
  OpenSearchTimeFieldName?: string;
  OpenSearchPipelineTemplate?: object;
  CsvHeaderExist?: boolean;
  CsvHeaders?: string;
  Filename?: string;
}

export interface EndPointInfo {
  ServiceType: string;
  URL: string;
  tenantName: string;
  SecurityLevel: string;
}

export const EnvSingleParm = {
  SystemAlertTopicArn: "SystemAlertTopic", // システムアラーとの発信用Topic:Arn [IoTEnvCDK-Common]
  OrionVpclinkId: "orion-vpclink-id", // orion-vpclink-id [IoTEnvCDK-orion-svc]
  OrionUrl: "orion-url", // orion-url [IoTEnvCDK-orion-svc]
  QuantumleapVpclinkId: "quantumleap-vpclink-id", // quantumleap-vpclink-id[IoTEnvCDK-quantumleap-fargate-svc]
  QuantumleapUrl: "quantumleap-url", // quantumleap-url[IoTEnvCDK-quantumleap-fargate-svc]
  OrionSubscriptionProviderToken: "orion-subscription-provider-token", // orion-subscription-provider-token[IoTEnvCDK-orion-svc]
  OrionRegistrationProviderToken: "orion-registration-provider-token", //orion-registration-provider-token[IoTEnvCDK-orion-svc]
  ProviderUrl: "Provider-url", // Registrations用Provider-url[IoTEnvCDK-provider-api]
  ProviderApiId: "Provider-api-id", // Registrations用Provider api-id[IoTEnvCDK-provider-api]
  ProviderApiResourceid: "Provider-api-resourceid", // Registrations用Provider api-resourceid[IoTEnvCDK-provider-api]
  CognitoDomainName: "cognito-domain-name", // cognito-domain-name[IoTEnvCDK-cognito-userpool]
  UserPoolId: "user-pool-id", //user-pool-id[IoTEnvCDK-cognito-userpool]
  TenantTableArn: "tenant-table-Arn", // tenant-table-Arn[IoTEnvCDK-management-tenant]
  GroupTableArn: "group-table-Arn", // group-table-Arn[IoTEnvCDK-management-group]
  UserTableArn: "user-table-Arn", // user-table-Arn[IoTEnvCDK-management-user]
  MessageConfigTableArn: "messageConfig-table-arn", // messageConfig-table-arn[IoTEnvCDK-sendmessage-api]
  MessageHistoryTableArn: "messageHistory-table-arn", // messageHistory-table-arn[IoTEnvCDK-sendmessage-api]
  SendmessageUrl: "sendmessage-url", // message[IoTEnvCDK-sendmessage-api]
  ParamTableArn: "param-table-Arn", // 各種パラメータテーブル
  ManagerIdentityPoolId: "identity-PoolId", // IoT-Manager Identity Pool [IoTEnvCDK-management-user]
  ManagerUserPoolClientId: "IoT-Manager/user-PoolClientId", // IoT-Manager ClientId [IoTEnvCDK-management-user]
  TruncateStateMachineArn: "truncate-state-machine-Arn", // Orion Entity Clear StepFunction[IoTEnvCDK-stepfunctions]
  OpensearchAccountAdminRoleArn: "opensearch-accountAdminRole-arn", // [IoTEnvCDK-open-search-svc]
  OpensearchDomainArn: "opensearch-domain-arn", // [IoTEnvCDK-open-search-svc]
  OpensearchDomainEndpoint: "opensearch-domain-endpoint", // [IoTEnvCDK-open-search-svc]
  OpensearchFirehoseRoleArn: "opensearch-firehose-role-arn", // [IoTEnvCDK-open-search-svc]
  OpensearchIdentityPoolId: "opensearch-identityPool-id", // [IoTEnvCDK-open-search-svc]
  OpensearchLambdaRoleArn: "opensearch-lambda-role-arn", // [IoTEnvCDK-open-search-svc]
  CkanDbInstance: "ckan-db-instance", // [IoTEnvCDK-ckan-rds]
  CkanDbEndpoint: "ckan-db-endpoint", // [IoTEnvCDK-ckan-rds]
  Ckan210DomainEndpoint: "ckan210-domain-endpoint",  // [IoTEnvCDK-ckan-fargate-svc]
  Ckan210ApiKey: "ckan210/apiKey", // [IoTEnvCDK-ckan-fargate-svc]
  Ckan210ResorceProvider: "ckan210-provider-service-token", // [IoTEnvCDK-ckan-fargate-svc]
  CrateDBClusterEndpoint: "cratedb-cluster-endpoint",
  CommonElasticacheEndpoint: "common-elasticache-endpoint",
  PublicDomain: "PUBLICDOMAIN", //  [IoTEnvCDK-public-site-pre]
  PublicSiteClientID: "PUBLICSITECLIENTID", //  [IoTEnvCDK-public-site-pre]
  FiwareAppApikey: "fiware-app/apikey", //  [IoTEnvCDK-fiware-app-pre]
  MetricCheckStateMachineArn: "metric-check-state-machine-Arn", //  [IoTEnvCDK-sensor-alert-functions]
  CodebuildRoleArn: "codebuild-role-arn", // 今日のS3からファイルを取得してくるCodeBuild用のRole [IoTEnvCDK-public-site-pre]
  PrivateReciveApiRoot:"private-recive-api-root" , // [IoTTenantCDK:tenant-devices]
  PrivateReciveApi:"private-recive-api" , // [IoTTenantCDK:tenant-devices]
  PublicReceiveApi: "public-recive-api", // 受信用APIのID[IoTTenantCDK:tenant-devices]
  PublicReceiveApiRoot: "public-recive-api-root", // 受信用APIのroot[IoTTenantCDK:tenant-devices]
  ReceiveDeveloperUsagePlanid: "recieve-developer-usage-planid", // 受信用APIのデベロッパーポータルユーザ用の使用量プラン[IoTTenantCDK:tenant-devices]
  InsertNewLineArn:"insert-new-line-arn" , // [IoTTenantCDK:tenant-devices]
  OpenApiWebAclArn: "open-api-WebAclArn", // [IoTTenantCDK:tenant-types]
};

export const TenantSingleParm = {
  DevicesBuildArn: "devices-build-arn", // テナント単位のIoTDeviceCDKを実行するstateMachineArn [IoTTenantCDK:tenant-mng-rols]
  UpdateQueueArn: "update-queue-arn", // テナント単位のOrionへのNgsiV2更新を実行するSQSのarn [IoTTenantCDK:tenant-devices]
  UpdateFunctinArn: "update-function-arn", // テナント単位のOrionへのNgsiV2更新を実行するLambdaのarn [IoTTenantCDK:tenant-devices]
  AuthorizerFunctionArn: "AuthorizerFunction-arn", // テナント単位のAPI権限チェック用オーサライザー [IoTTenantCDK:tenant-types]
  PublicDataLake: "public-data-lake", // テナント単位のpublic Bucket Name [IoTTenantCDK:tenant-devices]
  PrivateDataLake: "private-data-lake", // テナント単位のprivate Bucket Name [IoTTenantCDK:tenant-devices]
  ExclusiveDataLake: "exclusive-data-lake", // テナント単位のexclusive Bucket Name [IoTTenantCDK:tenant-devices]
  AnonymizedPersonalDataLake: "a-personal-data-lake", // テナント単位の匿名レベル Bucket Name [IoTTenantCDK:tenant-devices]
  BinaryDataLake: "binary-data-lake", // テナント単位のbinary Bucket Name  [IoTTenantCDK:tenant-devices]
  DeveloperUsagePlanid: "developer-usage-planid", // デベロッパーポータルユーザ用の使用量プラン  [IoTDeviceCDK:types]
  BatchCkanLambdaArn: "batch-ckan-lambda-arn", // Ckanデータ登録用関数Arn  [IoTTenantCDK:tenant-ckan]
  BatchOpenSearchLambdaArn: "batch-opensearch-lambda-arn", // Ckanデータ登録用関数Arn  [IoTTenantCDK:tenant-ckan]
  BatchOrionLambdaArn: "batch-orion-lambda-arn", // Ckanデータ登録用関数Arn  [IoTTenantCDK:tenant-ckan]
  MetricCheckTriggerArn: "metric-check-trigger-Arn", // Metric使用の監視sfcをトリガーするEventBridgeArn  [IoTTenantCDK:tenant-monitoring]
  GlueDatabaseName: "glue/databaseName", // Glueのデータベース名  [IoTDeviceCDK:datalake]
};
